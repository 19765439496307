.products {
    background-color: white;
    margin: 20px 0px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    table {
        button {
            white-space: normal;
        }
    }
}

.button-purchase{
    width: 100%
}
