.cookies-agree-block {
    position: absolute;
    border: 1px solid #808080;
    border-radius: 5px;
    bottom: 25px;
    left: 25px;
    background: white;
    max-width: 400px;
    padding: 10px;
    font-size: large;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 20px 20px rgba(0,0,0,0.22);
}
