.event_images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
    justify-items: center;
    grid-gap: 40px;

    .img-container {
        border: 1px solid rgb(207, 207, 207);
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        border-radius: 5px;
        padding: 5px;

        img {
            max-width: 300px;
            max-height: 300px;
            cursor: pointer;
            margin-bottom: 20px;
        }

        video {
            max-width: 300px;
            max-height: 300px;
            cursor: pointer;
            margin-bottom: 20px;
        }
    }

    .img-container:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 20px 20px rgba(0,0,0,0.22);
    }
}
