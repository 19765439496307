.element-group {
    margin: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    position: relative;

    .element-title {
        position: absolute;
        top: -12px;
        left: 20px;
        background: white;
    }

    .element-content {
        padding: 20px;
    }
}
