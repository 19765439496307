.article-block {
    background-color: white;
    margin-top: 20px;
    padding: 20px;

    ul {
        margin-top: 10px;
        font-size: 24px;
    }
}
