.form {
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 24px;

    .button {
        margin: 0px 20px;
    }
}
