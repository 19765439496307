.signin-list {
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 24px;

    li {
        margin: 20px 20px;
    }
}
