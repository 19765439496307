.element-group2 {
    margin-top:20px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    position: relative;
    background-color: white;

    .element-title2 {
        position: absolute;
        top: -14px;
        left: 20px;
        background: white;
        padding: 2px;
    }

    .element-content2 {
        margin-top: 24px;
    }
}

.element-group-padding{
    padding: 20px;
} 
