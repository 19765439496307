.page-content {
    padding-top: 20px;
}

.ant-card {
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.content-element {
    background-color: white;
    margin: 20px 0px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.no-padding {
    padding: 0px;
}
