@mixin flex-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header {
    background-color: white;
    box-shadow: 0 2px 10px 1px grey;
    z-index: 5;
    position: relative;
    justify-content: space-between;
    align-items: center;

    .title {
        border-bottom: 1px solid rgba(0,40,100,.12);
        width: 100%;
        padding: 16px 0px 16px 0px;

        .container {
            display: flex;
            justify-content: space-between;


            .logo {
                background-image: url('../../../public/logo512.png');
                background-size: contain;
                background-position: left;
                background-repeat: no-repeat;
                padding-left: 30px;
                width: 150px;
                font-weight: bold;

                span {
                    color: red;
                }
            }
        }
    }

    .menu {
        width: 100%;
    }
}
