.images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
    justify-items: center;
    grid-gap: 40px;

    img {
        max-width: 300px;
        max-height: 300px;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        cursor: pointer;
    }

    img:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 20px 20px rgba(0,0,0,0.22);
    }

    .img-container {

        .img-delete {
/*            margin-top: -25px;
            padding-left: 5px;
            text-align: center;*/
        }
    }
}

.show {
    position: fixed; /* Stay in place */
    z-index: 6; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.show .img-show {
    width: 95%;
    height: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
}

.show .bt-left {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 33%;
    z-index: 1;
    cursor: pointer;
}

.show .bt-right {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 33%;
    z-index: 1;
    cursor: pointer;
}

.show .bt-close {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 50px;
    width: 50px;
    z-index: 2;
    cursor: pointer;
    color: white;
    font-size: xx-large;
}

.img-show span {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
    cursor: pointer;
}

.img-show img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
}
