.loader-wrapper {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(2px);
    background: rgba(255,255,255,0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 900;

    .glb-loader {
        position: fixed;
        top: 50vh;
        left: 50vw;
        transform: translate(-50%, -50%);
        z-index: 999;
    }
}
