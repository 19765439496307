.App {
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-auto-columns: 100%;
    max-width: 1920px;
    margin: 0 auto;
}

.container {
    max-width: 1200px;
    min-height: 100%;
    margin: auto;
    padding: 0px 20px 0px 20px;
}