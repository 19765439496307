$scroll_width: 10px;
$scroll_height: 10px;

$scroll_thumb: #7ba3c8;
$scroll_border_radius: 3px;

.scroll {
    overflow: auto;

    &::-webkit-scrollbar {
        width: $scroll_width;
        height: $scroll_height;
    }

    &::-webkit-scrollbar-thumb {
        background: $scroll_thumb;
        border-radius: $scroll_border_radius;
    }

    &:hover::-webkit-scrollbar-thumb {
        background: darken($color: $scroll_thumb, $amount: 20);
    }

    &::-webkit-scrollbar-track {
        background-color: lighten($color: $scroll_thumb, $amount: 30);
    }
}

.content {
    background-color: #f5f7fb;
}

.footer {
    width: 100%;
    background: white;
    border-top: 1px solid rgba(0,40,100,.12);
    margin-top: 20px;

    .container {
        display: flex;
        justify-content: space-evenly;

        .copyright-block {
            padding: 12px 0px 12px 0px;
            width: 50%;
            text-align: left;
        }

        .email-block {
            padding: 12px 0px 12px 0px;
            width: 50%;
            text-align: right;
        }
    }
}
